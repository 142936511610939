import * as React from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CallIcon from "@mui/icons-material/Call";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, CardActions } from "@mui/material";

import sa from "./Images/whatsapp/Whatsapp-SA.png";
import london from "./Images/whatsapp/Whatsapp-UK.png";
import CardMedia from "@mui/material/CardMedia";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { getWhatsappModalOpen, setWhatsappModalOpen } from "./appSlice";
import theme from "./Styles/theme";
import ClearIcon from "@mui/icons-material/Clear";
import { showSuccess, showError } from "./notificationsSlice";

export default function SendWhatsapp() {
  const dispatch = useDispatch();
  const whatsapp_us_modal_open = useSelector(getWhatsappModalOpen);
  const whatsapp = "https://wa.me/message/MKQF3S3FNOS4E1";
  const whatsappUK = "https://wa.me/+447709087023/";

  const handleClose = () => {
    dispatch(setWhatsappModalOpen(false));
  };

  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      open={whatsapp_us_modal_open}
      onClose={handleClose}
    >
      <DialogTitle>
        <ClearIcon sx={{ top: 0, right: 0 }} onClick={handleClose} />

        <Typography style={theme.whatsappMobileHeader.root}>
          Send us a Whatsapp
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          gap={2}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item align="center" xs={12} md={5}>
            <Card sx={{ minHeight: "350px" }}>
              <CardContent>
                <CardMedia component="img" height="140" image={sa} width="100%" alt="SA" />
                <br />
                <a
                  style={{ "text-decoration": "none" }}
                  target="_blank"
                  href={whatsapp}
                >
                  <WhatsAppIcon
                    fontSize="large"
                    sx={{
                      color: "#2196f3",
                      "&:hover": {
                        color: "#2196f3",
                      },
                    }}
                    onClick={whatsapp}
                  />
                </a>
                <br />
                <h>
                  South Africa
                  <br />
                  <h> Cauchy Consult (Pty) ltd </h> <br />
                  <a
                    style={{ "text-decoration": "none" }}
                    target="_blank"
                    href={whatsapp}
                  >
                    +27 62 197 1743
                  </a>
                </h>
              </CardContent>
            </Card>{" "}
          </Grid>

          <Grid item align="center" xs={12} md={5}>
            <Card sx={{ minHeight: "350px" }}>
              <CardContent>
                <CardMedia
                  component="img"
                  height="140"
                  image={london}
                  alt="UK"
                />
                <br />
                <a
                  style={{ "text-decoration": "none" }}
                  target="_blank"
                  href={whatsappUK}
                >
                  <WhatsAppIcon
                    fontSize="large"
                    sx={{
                      color: "#2196f3",
                      "&:hover": {
                        color: "#2196f3",
                      },
                    }}
                    onClick={whatsappUK}
                  />
                </a>
                <br />
                <h>
                  United Kingdom
                  <br />
                  <h> Cauchy Consult UK LTD </h> <br />
                  <a
                    style={{ "text-decoration": "none" }}
                    target="_blank"
                    href={whatsappUK}
                  >
                    +44 7709 087023
                  </a>
                </h>
              </CardContent>
            </Card>{" "}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
